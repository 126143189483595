import React from 'react'

export const Ecomix170 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1277"
    height="777"
    viewBox="0 0 1277 777"
    {...props}
  >
    <defs>
      <pattern
        id="pattern"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 212 188"
      >
        <image
          width="212"
          height="188"
          href="../ecomix-170/PA01.161.13E.2311%20_2.png"
        />
      </pattern>
      <pattern
        id="pattern-2"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 159 183"
      >
        <image
          width="159"
          height="183"
          href="../ecomix-170/PA01.161.13E.2311%20_1.png"
        />
      </pattern>
      <pattern
        id="pattern-3"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 139 183"
      >
        <image
          width="139"
          height="183"
          href="../ecomix-170/PA01.161.13E.2311%20_3.png"
        />
      </pattern>
      <pattern
        id="pattern-4"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 17 19"
      >
        <image
          width="17"
          height="19"
          href="../ecomix-170/PA01.161.13E.2311%20_4.png"
        />
      </pattern>
      <pattern
        id="pattern-5"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 44 29"
      >
        <image
          width="44"
          height="29"
          href="../ecomix-170/PA01.161.13E.2311%20_8.png"
        />
      </pattern>
      <pattern
        id="pattern-6"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 38 28"
      >
        <image
          width="38"
          height="28"
          href="../ecomix-170/PA01.161.13E.2311%20_6.png"
        />
      </pattern>
      <pattern
        id="pattern-7"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 18 22"
      >
        <image
          width="18"
          height="22"
          href="../ecomix-170/PA01.161.13E.2311%20_7.png"
        />
      </pattern>
      <pattern
        id="pattern-8"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 34 23"
      >
        <image
          width="34"
          height="23"
          href="../ecomix-170/PA01.161.13E.2311%20_9.png"
        />
      </pattern>
      <pattern
        id="pattern-9"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 46 60"
      >
        <image
          width="46"
          height="60"
          href="../ecomix-170/PA01.161.13E.2311%20_10.png"
        />
      </pattern>
      <pattern
        id="pattern-10"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 37 25"
      >
        <image
          width="37"
          height="25"
          href="../ecomix-170/PA01.161.13E.2311%20_12.png"
        />
      </pattern>
      <pattern
        id="pattern-11"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 56 48"
      >
        <image
          width="56"
          height="48"
          href="../ecomix-170/PA01.161.13E.2311%20_14.png"
        />
      </pattern>
      <pattern
        id="pattern-12"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 114 145"
      >
        <image
          width="114"
          height="145"
          href="../ecomix-170/PA01.161.13E.2311%20_15.png"
        />
      </pattern>
      <pattern
        id="pattern-13"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 40 72"
      >
        <image
          width="40"
          height="72"
          href="../ecomix-170/PA01.161.13E.2311%20_16.png"
        />
      </pattern>
      <pattern
        id="pattern-14"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 257 263"
      >
        <image
          width="257"
          height="263"
          href="../ecomix-170/PA01.161.13E.2311%20_13.png"
        />
      </pattern>
      <pattern
        id="pattern-15"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 197 230"
      >
        <image
          width="197"
          height="230"
          href="../ecomix-170/PA01.161.13E.2311%20_18.png"
        />
      </pattern>
    </defs>
    <g
      className="vista-g"
      id="Group_84"
      data-name="Group 84"
      transform="translate(-337 -152)"
    >
      <rect
        id="PA01.161.13E.2311__2"
        data-name="PA01.161.13E.2311 _2"
        width="296"
        height="263"
        transform="translate(582 217)"
        fill="url(#pattern)"
      />
      <rect
        id="PA01.161.13E.2311__1"
        width="222"
        height="256"
        transform="translate(337 296)"
        fill="url(#pattern-2)"
        data-url="https://shopirbal.com/pt/produto/cremalheira-de-ferro-fundido-170-175-190-200/"
        data-name="Cremalheira de Ferro Fundido 170-175-190-200"
      />
      <rect
        id="PA01.161.13E.2311__3"
        data-name="PA01.161.13E.2311 _3"
        width="194"
        height="256"
        transform="translate(520 618)"
        fill="url(#pattern-3)"
      />
      <rect
        id="PA01.161.13E.2311__4"
        data-name="PA01.161.13E.2311 _4"
        width="24"
        height="27"
        transform="translate(669 587)"
        fill="url(#pattern-4)"
      />
      <rect
        id="PA01.161.13E.2311__8"
        width="62"
        height="41"
        transform="translate(758 516)"
        fill="url(#pattern-5)"
        data-url="https://shopirbal.com/pt/produto/rolamento-rl-6207-2rs/"
        data-name="Rolamento do Cubo do Balde RL 6207 2RS"
      />
      <image
        id="PA01.161.13E.2311__11"
        data-name="PA01.161.13E.2311 _11"
        width="407"
        height="563"
        transform="translate(819 366)"
        href="../ecomix-170/PA01.161.13E.2311%20_11.png"
      />
      <rect
        id="PA01.161.13E.2311__6"
        width="53"
        height="40"
        transform="translate(878 457)"
        fill="url(#pattern-6)"
        data-url="https://shopirbal.com/pt/produto/pinhao-carreto-b1-150-cavilha/"
        data-name="Pinhão/Carreto B1-150 + Cavilha"
      />
      <rect
        id="PA01.161.13E.2311__7"
        width="25"
        height="31"
        transform="translate(953 336)"
        fill="url(#pattern-7)"
        data-url="https://shopirbal.com/pt/produto/interruptor-b1/"
        data-name="Interruptor B1"
      />
      <rect
        id="PA01.161.13E.2311__9"
        width="46"
        height="32"
        transform="translate(1166 379)"
        fill="url(#pattern-8)"
        data-url="https://shopirbal.com/pt/produto/rolamento-6303-2rs/"
        data-name="Rolamento do Lado do Pinhão RL 6303 2RS"
      />
      <rect
        id="PA01.161.13E.2311__10"
        width="65"
        height="85"
        transform="translate(1297 791)"
        fill="url(#pattern-9)"
        data-url="https://shopirbal.com/pt/produto/roda-macica-200-com-freio/"
        data-name="Roda Maciça Ø200 com Freio"
      />
      <rect
        id="PA01.161.13E.2311__12"
        data-name="PA01.161.13E.2311 _12"
        width="52"
        height="36"
        transform="translate(1270 612)"
        fill="url(#pattern-10)"
      />
      <rect
        id="PA01.161.13E.2311__14"
        data-name="PA01.161.13E.2311 _14"
        width="79"
        height="68"
        transform="translate(1243 316)"
        fill="url(#pattern-11)"
      />
      <rect
        id="PA01.161.13E.2311__15"
        data-name="PA01.161.13E.2311 _15"
        width="160"
        height="204"
        transform="translate(1337 152)"
        fill="url(#pattern-12)"
      />
      <rect
        id="PA01.161.13E.2311__16"
        width="56"
        height="102"
        transform="translate(1179 170)"
        fill="url(#pattern-13)"
        data-url="https://shopirbal.com/pt/produto/correia-6pj-636-elastica/"
        data-name="Correia 6PJ 636 Elástica"
      />
      <image
        id="PA01.161.13E.2311__19"
        width="50"
        height="58"
        transform="translate(1404 497)"
        href="../ecomix-170/PA01.161.13E.2311%20_19.png"
        data-url="https://shopirbal.com/pt/produto/condensador-700wt/"
        data-name="Condensador 700W"
      />
      <rect
        id="PA01.161.13E.2311__13"
        width="105"
        height="107"
        transform="translate(1376 618)"
        fill="url(#pattern-14)"
        data-url="https://shopirbal.com/pt/produto/motor-ele-700w/"
        data-name="Motor ELE 700W"
      />
      <rect
        id="PA01.161.13E.2311__18"
        width="79"
        height="92"
        transform="translate(1535 537)"
        fill="url(#pattern-15)"
        data-url="https://shopirbal.com/pt/produto/ventoinha-para-motor-700w/"
        data-name="Ventoinha para Motor 700W"
      />
    </g>
  </svg>
)
